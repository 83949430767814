import emailValidator from "email-validator";
import { getDoPostbackParameters } from "./getdopostbackparameters";
import dopostback from "./window.__dopostback";

const validateIsValidData = (elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): boolean => {
  if(elem instanceof HTMLInputElement && elem.getAttribute("type") === "checkbox" && !elem.checked)
    return false;
  if (
    elem.hasAttribute("data-validation-required") &&
    ((elem.nodeName === "INPUT" &&
        (((elem.getAttribute("type") === "text" ||
            elem.getAttribute("type") === "tel" ||
            elem.getAttribute("type") === "email" ||
          elem.getAttribute("type") === "password") &&
          elem.value === ""))) ||
      (elem.nodeName === "SELECT" && elem.value === "") ||
      (elem.nodeName === "TEXTAREA" && elem.value === ""))
  )
    return false;
  if (
    elem.hasAttribute("data-validation-required") &&
    elem.nodeName === "INPUT" &&
    elem.getAttribute("type") === "radio" &&
    document.querySelector(`input[name="${elem.name}"]:checked`) === null
  )
    return false;
  if (
    elem.hasAttribute("data-validation-maxlength") &&
    elem.value.length >
    parseInt(elem.getAttribute("data-validation-maxlength"), 10)
  )
    return false;
  if (
    elem.hasAttribute("data-validation-notvalue") &&
    elem.value === elem.getAttribute("data-validation-notvalue")
  )
    return false;
  if (elem.hasAttribute("data-validation-match")) {
    const comparisonElement = document.getElementById(
      elem.getAttribute("data-validation-match")
    ) as HTMLInputElement;
    if (elem.value !== comparisonElement.value) return false;
  }
  if (
    elem.hasAttribute("data-validation-validemail") &&
    !emailValidator.validate(elem.value)
  )
    return false;
  return true;
};

const addActionRequired = (itemId: string, itemText: string): void => {
  if (document.querySelector(".js-validate-show-action-below") !== null) {
    // generate the action required div if necessary
    if (document.querySelector(".js-validate-action-required") === null) {
      const actionRequired = document.createElement("div");
      actionRequired.className =
        "card d-md-none js-validate-action-required animated zoomInDown";
      actionRequired.innerHTML =
        '<h4 class="card-header bg-danger text-white"><i class="fa-regular fa-triangle-exclamation"></i>&nbsp;&nbsp;Action Required</h4><ul class="list-group list-group-flush js-validate-action-required-content"></ul>';
      document
        .querySelector(".js-validate-show-action-below")
        .insertAdjacentElement("afterend", actionRequired);
    }
    if (
      document.querySelector(
        `.js-validate-action-required-content [data-source-id=${itemId}]`
      ) === null
    ) {
      const actionItem = document.createElement("a");
      actionItem.href = `#${itemId}`;
      actionItem.className = "list-group-item list-group-item-danger";
      actionItem.dataset.sourceId = itemId;
      const sanitizeInput = document.createElement("span");
      sanitizeInput.textContent = itemText;
      actionItem.innerHTML = `<i class="fa-solid fa-angle-up"></i>&nbsp;&nbsp;${sanitizeInput.textContent}`;
      document
        .querySelector(".js-validate-action-required-content")
        .appendChild(actionItem);
    }
  }
};

const removeActionRequired = (itemId: string): void => {
  if (
    document.querySelector(
      `.js-validate-action-required-content [data-source-id="${itemId}"]`
    ) !== null
  ) {
    document
      .querySelector(
        `.js-validate-action-required-content [data-source-id="${itemId}"]`
      )
      .remove();
    if (
      !document
        .querySelector(".js-validate-action-required-content")
        .hasChildNodes()
    ) {
      document.querySelector(".js-validate-action-required").remove();
    }
  }
};

const validateIsValidElement = (elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): boolean => {
  if (!validateIsValidData(elem)) {
    // If a radio button is being validated, all other radio buttons with the same name are handled as well.
    if (elem.nodeName === "INPUT" && elem.getAttribute("type") === "radio") {
      document
        .querySelectorAll(`input[name="${elem.name}"]`)
        .forEach((value) => {
          value.classList.remove("is-valid");
          value.classList.add("is-invalid");
        });
    } else {
      elem.classList.remove("is-valid");
      elem.classList.add("is-invalid");
    }
    if (elem.parentNode.querySelector(".invalid-feedback") !== null) {
      addActionRequired(
        elem.id,
        elem.parentNode.querySelector(".invalid-feedback").textContent
      );
    }
    return false;
  }
  // If a radio button is being validated, all other radio buttons with the same name are handled as well.
  if (elem.nodeName === "INPUT" && elem.getAttribute("type") === "radio") {
    document.querySelectorAll(`input[name="${elem.name}"]`).forEach((value) => {
      value.classList.remove("is-invalid");
      value.classList.add("is-valid");
    });
  } else {
    elem.classList.remove("is-invalid");
    elem.classList.add("is-valid");
  }
  if (
    document.querySelector(
      `.js-validate-action-required-content [data-source-id="${elem.id}"]`
    ) !== null
  ) {
    removeActionRequired(elem.id);
  }
  return true;
};

const validateAllValid = (formElem?: HTMLFormElement): boolean => {
  let isValid = true;
  const baseElem = formElem === undefined ? document.body : formElem;
  baseElem
    .querySelectorAll(".js-validation")
    .forEach((value: HTMLInputElement) => {
      if (!validateIsValidElement(value)) {
        isValid = false;
      }
    });
  if (
    !isValid &&
    baseElem.querySelector(".js-validate-action-required") !== null
  ) {
    const elem = baseElem.querySelector(".js-validate-action-required");
    const newElem = elem.cloneNode(true);
    elem.parentNode.replaceChild(newElem, elem);
  }
  return isValid;
};

const addElementValidation = (qs: string): void => {
  document.querySelectorAll(qs).forEach((value) => {
    value.classList.add("js-validation");
  });
};

const removeElementValidation = (qs: string): void => {
  document.querySelectorAll(qs).forEach((value) => {
    value.classList.remove("js-validation", "is-invalid", "is-valid");
    if (
      document.querySelector(".js-validate-action-required-content") !== null &&
      document.querySelector(
        `.js-validate-action-required-content [data-source-id="${value.id}"]`
      ) !== null
    ) {
      document
        .querySelector(
          `.js-validate-action-required-content [data-source-id="${value.id}"]`
        )
        .remove();
      if (
        !document
          .querySelector(".js-validate-action-required-content")
          .hasChildNodes()
      ) {
        document.querySelector(".js-validate-action-required").remove();
      }
    }
  });
};

const validateWebformsButton = (
  netButton: HTMLButtonElement,
  performOnValid: Function
): void => {
  const btn = netButton;
  if (btn !== null) {
    const onClickText = btn.getAttribute("onclick");
    const doPostbackParameters = getDoPostbackParameters(onClickText);
    btn.onclick = null;
    btn.addEventListener("click", (e) => {
      if (validateAllValid()) {
        if (performOnValid !== null) {
          performOnValid();
        }
        // eslint-disable-next-line no-underscore-dangle
        dopostback(
          doPostbackParameters.eventTarget,
          doPostbackParameters.eventArgument
        );
      } else {
        e.preventDefault();
      }
    });
  }
};

export {
  validateIsValidData,
  addActionRequired,
  removeActionRequired,
  validateIsValidElement,
  validateAllValid,
  addElementValidation,
  removeElementValidation,
  validateWebformsButton,
};
